import React from "react";

import "./Contact.scss";

export const Contact = () => (
    <div className="contact">
        <h2>Kontakt</h2>
        <p>Práce jako na kostele s.r.o.</p>
        <p>Bc. Marek Sláma</p>
        <p>+420 777 587 427</p>
        <p>Tasov 32, 675 79 Tasov</p>
        <p>pracejakonakostele@email.cz</p>
        <p>IČ: 07480423</p>
        <p>www.pracejakonakostele.cz</p>
        <br />
        <p>
            Dostupnost služeb především v okolí obcí Tasov, Dolní Heřmanice, Kamenná, Čikov, Jasenice, Pyšel,
            Budišov, Hodov, Velké Meziříčí, Nárameč, Trnava, Rudíkov, Jabloňov, Březejc, Ruda, Ocmanice, Pozďatín,
            Smrk, Náměšť nad Oslavou, Třebíč, Velká Bíteš, Osová Bítýška, Nové Sady, Jinošov, Krokočín, Oslavice,
            Uhřínov, Kralice nad Oslavou, Ořechov, Březí, Vlkov, Skřinářov, Vladislav, Studenec, Veverská Bítýška,
            Rosice, Zbýšov, Kuřim, Tišnov, Žďárec, Březí, Vidonín, Křižanov, Stránecká Zhoř, Netín, Měřín,
            Kralice nad Oslavou, Zbraslav, Bystrc, Kamenice, Mostiště, Lavičky, Uhřínov, Záblatí, Baliny, Oslavice,
            Petráveč, Dolní Radslavice, Lhotky, Kozlov, Dobrá Voda, Heřmanov, Skřinářov, Řikonín, Katov, Křižínkov,
            Pánov, Křoví, Maršov, Přibyslavice, Jinošov, Stanoviště a Krokočín.
        </p>
    </div>
);
