import {galleryUrl, galleryPreviewUrl} from "../../constants"

const createSrc = (imageUrl) => `${galleryUrl}/${imageUrl}`;

const createThumbnail = (imagePreviewurl) => `${galleryPreviewUrl}/${imagePreviewurl}`;

const createPhoto = (src, thumbnail) => ({
    src: createSrc(src),
    thumbnail: createThumbnail(thumbnail),
});

export const photos = [
    "Borovice.jpg",
    "Duby.jpg",
    "Jasan.jpg",
    "Kostel.jpg",
    "Lípa.jpg",
    "Net4gas.jpg",
    "Práce v koruně.jpg",
    "Pyšel náměstí.jpg",
    "Smrky.jpg",
    "Střecha.jpg",
    "Špalky.jpg",
    "Údržba zahrady Brno.jpg",
    "Vaneč.jpg",
    "1616186539666.JPG",
    "1616186539765.JPG",
    "1616186539843.JPG",
    "1616186539910.JPG",
    "1616187361022.jpg",
    "1616187361076.jpg",
    "1616187361133.jpg",
    "1616187361190.jpg",
    "1616187361246.jpg",
    "1616187361307.jpg",
    "1616187361363.jpg",
    "1616187361418.jpg",
    "1616187361473.jpg",
].map((imageUrl) => createPhoto(imageUrl, imageUrl));
