import React from "react";

import "./HighRise.scss";

export const HighRise = () => (
    <div className="high-rise">
        <h2>Výškové práce</h2>
        <p>Provádíme práce ve výškách a nad volnou hloubkou za pomoci horolezecké techniky a vysokozdvižné pracovní plošiny.</p>
        <p>Nabízíme:</p>
        <ul style={{marginTop: 0}}>
            <li>drobné opravy střech,</li>
            <li>opravy klempířských prvků střech,</li>
            <li>čištění střech,</li>
            <li>nátěry plechových střech</li>
            <li>bourací práce ve výškách,</li>
            <li>další práce dle přání zákazníka, např. čištění okapů a studní, opravy střešní krytiny atd.</li>
        </ul>
    </div>
);
