import React, { useState } from "react";

import { MenuItem } from "../MenuItem";
import { menuItems } from "./constants";
import "./Menu.scss";

export const Menu = () => {
    const [opened, setOpened] = useState(false);

    return (
        <div className="menu">
            <div className="menu__button" onClick={() => setOpened(!opened)}>
                MENU {opened ? "▲" : "▼"}
                {opened && (
                    <div className="menu__dropdown">
                        <div className="menu__dropdown_content">
                            {menuItems.map(({ title, link }) => (
                                <MenuItem key={title} {...{ title, link }} />
                            ))}
                            <a
                                className="menu-item"
                                activeClassName="menu-item--active"
                                href="https://www.instagram.com/vyskove_prace_arboristika/"
                            >
                                {"Instagram".toUpperCase()}
                            </a>
                        </div>
                    </div>
                )}
            </div>
            <span className="menu__wide">
                {menuItems.map(({ title, link }) => (
                    <MenuItem key={title} {...{ title, link }} />
                ))}
                <a
                    href="https://www.instagram.com/vyskove_prace_arboristika/"
                    style={{ display: "flex" }}
                >
                    <img
                        title="Instagram"
                        src="instagram_minified.png"
                        style={{ height: 46 }}
                    />
                </a>
            </span>
        </div>
    );
};
