import React from "react";

import "./Title.scss";

const TITLE_TEXT = "Práce jako na kostele";
const SUBTITLE_TEXT = "Arboristika a výškové práce";

export const Title = () => (
    <h1 className="title">
        <span className="title__title">
            {TITLE_TEXT.toUpperCase()} <span className="sub">s.r.o.</span>
        </span>
        <span className="title__subtitle">
            {SUBTITLE_TEXT.toUpperCase()}
        </span>
    </h1>
);
