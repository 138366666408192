import React, {Fragment} from "react";

import {referenceUrl} from "../../constants";

const createUrl = (imageName) => `${referenceUrl}/${imageName}`;

export const references = [
    {
        title: "Ing. Ludvík Martínek Ph.D.",
        imageUrlList: [],
        content: (
            <i>
                <p>Dobrý den,</p>
                <p>V průběhu letošního roku, tedy roku 2020, ostatně i v roce loňském a předloňském, jste pro nás (pro městys Ostrov nad Oslavou) provedl
                celou řadu odborných prací spojených s péčí o naši zeleň. Především díky Vám byl odborně ošetřen velmi starý (cca 250 let) jasan „u křížku“,
                který byl některými skeptiky odsouzen k pokácení a nyní má šanci k dalšímu růstu. Ano, je také pravdou, že v některých případech (především
                z důvodu bezpečnosti a také pro chatrné zdraví některých našich stromů) se kácení nemůžeme vyhnout. Také za tuto práci by jsem Vám chtěl
                poděkovat a to nejenom proto, že jste ji prováděl se značnou odbornou erudicí, ovšem i proto, že nezapomínáte na opětovnou výsadbu nových stromů.</p>
                <p>Ještě jednou Vám tímto velmi děkuji a přeji Vám klidné prožití svátků, pohodu, štěstí a především hodně zdraví.</p>
                <p>Ing. Ludvík Martínek Ph.D.,<br />
                starosta</p>
            </i>
        ),
    },
    {
        title: "Marek Janoušek",
        imageUrlList: [createUrl("mara_1.jpg")],
        content: (
            <i>
                <p>
                    Musím sdílet svoji skvělou zkušenost s firmou <b>Práce jako na kostele</b>.
                    Několik týdnů jsem sháněl někoho, kdo nám pokácí 3 obrovské stromy. Slibů bylo
                    mnoho, ale nikdo nepřijel. Po telefonátu do této firmy jsme se okamžitě domluvili
                    a do 3 dnů byly všechny stromy dole. Jednání i práce bylo s profesionálním přístupem.
                </p>
                <p>
                    Díky moc
                </p>
            </i>
        ),
    },
    {
        title: "Pavel Pacek",
        imageUrlList: [createUrl("pacek_1.jpg"), createUrl("pacek_2.jpg"), createUrl("pacek_3.jpg"), createUrl("pacek_4.jpg")],
        content: (
            <i>
                <p>
                    Dobrý den,
                    byli jsme velmi spokojeni s pokácením našich stromů. Práce byla provedena rychle,
                    bez problémů a s příjemným jednáním.
                </p>
                <p>
                    Vřele všem doporučujeme!!!
                </p>
            </i>
        ),
    },
    {
        title: "Mgr. Martina Brestovská",
        imageUrlList: [createUrl("brestovska_1.jpg")],
        content: (
            <i>
                <p>
                    Skvělá spolupráce, super domluva, rychlá reakce, kvalitní práce a to vše
                    profesionálně a s úsměvem. Na čem jsme se domluvili, bylo opravdu hotovo
                    v daném termínu a bez problémů. Díky moc, ozvu se znovu.
                </p>
                <p>
                    Mgr. Martina Brestovská<br />
                    Starostka Obce Pyšel
                </p>
            </i>
        ),
    },
    {
        title: "Dušan K.",
        content: (
            <Fragment>
                <h4>Popis zakázky</h4>
                <p>Skácení a zpracování dvou borovic v těsné blízkosti rekreačního objektu. Povolení máme. Obvod kmene ve 1,3 metrech nad zemí: 130 a 135 cm.</p>
                <h4>Celkové hodnocení</h4>
                <p>Nakonec jsme borovice nepokáceli, pan Sláma je zkontroloval, vyřezal suché větve a stromy můžou žít dál. Jsem rád, že to takhle dopadlo. Děkuju za perfektní komunikaci, rady a za provedenou práci.</p>
                <a href="https://www.nejremeslnici.cz/reference/481837-rizikove-kaceni">Odkaz na portál nejremeslnici.cz</a>
            </Fragment>
        ),
    },
    {
        title: "Gala M.",
        content: (
            <Fragment>
                <h4>Popis zakázky</h4>
                <p>Na budovách školy máme asi 7 děr ve fasádě od Strakapouda, většina děr je v úrovni třetího patra, hledáme horolezce který je schopen tyto díry zapravit.</p>
                <p>Při bližším ohledání, bylo děr více, další praskliny na fasádě a potřeba ostříhat větve stromu, které narážely do fasády.</p>
                <h4>Celkové hodnocení</h4>
                <p>Velká spokojenost, radost spolupracovat.</p>
                <a href="https://www.nejremeslnici.cz/reference/449845-horolezec">Odkaz na portál nejremeslnici.cz</a>
            </Fragment>
        ),
    },
    {
        title: "Naďa Dvořáková",
        imageUrlList: [createUrl("dvorakova.jpg")],
        content: (
            <i>
                <p>
                    Dne 27.4. 2020 jsem zadala poptávku na pokácení 1 dubu a 1 břízy na soukromém pozemku.
                </p>
                <p>
                    Během dvou dnů jsem do emailu obdržela 4 nabídky, vybrala jsem si firmu „Práce jak na kostele“.
                    Pan Marek Sláma telefonicky požádal o zaslání fota a obratem mi sdělil cenu a termín na pondělí 29. 4. 2020.
                    Cena za pokácení 2 stromů, odvětvení, rozřezání na metrové špalky byla velmi potěšující.
                    P.&nbsp;Slámu doporučuji všem, kdo potřebují tuto službu, za solidní a vřelé jednání si zaslouží takovou recenzi.
                    My moc děkujeme
                </p>
                <p>
                    Naďa Dvořáková
                </p>

            </i>
        )
    },
    {
        title: "B. Kučerová",
        content: (
            <Fragment>
                <h4>Popis zakázky</h4>
                <p>Potřebovala jsem prořezat a upravit dva ovocné stromy, které několik let nebyly odborně prořezány.</p>
                <h4>Celkové hodnocení</h4>
                <p>S realizací zakázky jsem maximálně spokojená, řemeslník nejen dobře provedl to, na čem jsme se dohodli, ale ještě něco navíc, a to vše za velmi slušnou cenu. Hodnotím také příjemné a sympatické vystupování</p>
                <a href="https://www.nejremeslnici.cz/reference/502614-prorezavka-ovocnych-stromu">Odkaz na portál nejremeslnici.cz</a>
            </Fragment>
        ),
    },
];
