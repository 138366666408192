import React from "react";

import "./WorkingPlatform.scss";

export const WorkingPlatform = () => (
    <div className="working-platform">
        <h2>Pracovní plošina</h2>
        <p>K práci využíváme přívěsnou pracovní plošinu, kterou nabízíme i k pronájmu.</p>
        <br />
        <p>Parametry plošiny:</p>
        <ul style={{marginTop: 0}}>
            <li>Max. pracovní výška 25 m</li>
            <li>Max. boční dosah 13 m</li>
            <li>Průjezdná výška 2,1 m</li>
            <li>Průjezdná šířka 1,8 m</li>
            <li>Šířka při zapatkování 4,25×4,25 m</li>
            <li>Pohon na baterie/benzínový motor</li>
        </ul>
        <p>Cena nájmu:</p>
        <ul style={{marginTop: 0}}>
            <li>1–3 dny 4 200 Kč</li>
            <li>4–5 dnů 4 000 Kč</li>
            <li>6–21 dnů 3 800 Kč</li>
            <li>22 a více 3 400 Kč</li>
        </ul>
        <p>Uvedené ceny jsou za 1 den zapůjčení, bez DPH 21 %.</p>
    </div>
);
