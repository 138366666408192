import React from "react";

import {Title} from "../../components";
import "./Home.scss";

export const Home = () => (
    <div className="home">
        <Title />
    </div>
);
