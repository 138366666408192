import React from "react";
import GalleryView from "react-grid-gallery";

import {photos} from "./constants";
import "./Gallery.scss";

export const Gallery = () => (
    <div className="gallery">
        <h2>Galerie</h2>
        <p />
        <GalleryView
            images={photos}
            enableImageSelection={false}
            backdropClosesModal={true}
            imageCountSeparator="/"
        />
    </div>
);
