import React from "react";

import "./Footer.scss";

export const Footer = () => (
    <div className="footer">
        <a className="footer__link" href="http://pavelpo.cz/">
            Pavel Pospíšil 2018–{(new Date()).getFullYear()}
        </a>
        <p className="footer__cookies">Stránka využívá soubory cookies pro měření návštěvnosti. Návštěvou stránky souhlasíte s jejich použitím.</p>
    </div>
);
