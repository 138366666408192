import React from "react";
import { NavLink } from "react-router-dom";

import "./MenuItem.scss";

export const MenuItem = ({ title, link }) => (
    <NavLink
        className="menu-item"
        activeClassName="menu-item--active"
        to={link}
    >
        {title.toUpperCase()}
    </NavLink>
);
