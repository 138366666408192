import React, {Fragment} from "react";

import {references} from "./data";
import "./Reference.scss";

export const Reference = () => (
    <div className="reference">
        <h2>Reference</h2>
        {references.map(({content, title, imageUrlList}) => (
            <Fragment key={title}>
                <div className="reference__item">
                    <h3>{title}</h3>
                    <div className="reference__content">{content}</div>
                    {imageUrlList && (
                        <div className="reference__images">
                            {imageUrlList.map((imageUrl) => (
                                <a key={imageUrl} href={imageUrl}>
                                    <img alt="Reference" src={imageUrl } />
                                </a>
                            ))}
                        </div>
                    )}
                </div>
            </Fragment>
        ))}
    </div>
);
