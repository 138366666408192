import React from "react";

import "./Arborist.scss";

export const Arborist = () => (
    <div className="arborist">
        <h2>Arboristika</h2>
        <p>Zabýváme se službami spojenými s péčí o stromy. Specializujeme se na stromolezectví, řezy v koruně vzrostlých stromů, rizikové kácení, výsadbu a povýsadbovou péči.</p>
        <p>Naše kvalifikace: ETW – European tree worker, ČCA - Český certifikovaný arborista, kurz obsluha motorové pily, osvědčení o odborné způsobilosti k provádění prací ve výškách a nad volnou hloubkou, osvědčení pro obsluhu pracovní plošiny, pojištění odpovědnosti.</p>
        <ul>
            <li>Díky posouzení stavu dřeviny a vhodně zvolenému a provedenému řezu jsme schopni prodloužit délku jejího života, zvýšit bezpečnost nebo ji esteticky upravit.</li>
            <li>V případě suchých dřevin a jedinců nevyhovujících bezpečnostním požadavkům nabízíme možnost rizikového kácení buď pomocí lanových technik, nebo za využití pracovní plošiny s dosahem 25 m.</li>
            <li>U nestabilních stromů nabízíme instalaci bezpečnostních vazeb, které zajistí stabilitu koruny.</li>
            <li>Dále jsme schopni vybrat vhodný druh pro výsadbu s ohledem na poměry daného stanoviště. Zajišťujeme také výsadbu a následnou péči nutnou pro správné zapěstování koruny.</li>
            <li>Poskytujeme i možnost zpracování dřevní hmoty. Nabízíme řezání kmenů a větví na potřebné délky, štěpkování větví a kmenů do průměru 8 cm. Po dohodě zajistíme také odvoz či odkup nepotřebné dřevní hmoty.</li>
        </ul>
    </div>
);
