export const menuItems = [
    {
        title: "Arboristika",
        link: "/arboristika",
    },
    {
        title: "Pracovní plošina",
        link: "/pracovni-plosina",
    },
    {
        title: "Výškové práce",
        link: "/vyskove-prace",
    },
    {
        title: "Galerie",
        link: "/galerie",
    },
    {
        title: "Ceník",
        link: "/cenik",
    },
    {
        title: "Palivové dřevo",
        link: "/palivove-drevo",
    },
    {
        title: "Reference",
        link: "/reference",
    },
    {
        title: "Kontakt",
        link: "/kontakt",
    },
];
