import React from "react";

import "./Firewood.scss";

export const Firewood = () => (
    <div className="firewood">
        <h2>Palivové dřevo</h2>
        <p>
            Nabízíme prodej měkkého štípaného dříví. Dřevo štípeme standartně na délky 33cm, po
            dohodě lze nastavit štípací automat v rozmezí 25-50 cm délka polen. Dřevo vozíme ve
            sklopném vozíku o objemu 5 m³. <b>Cena za 1 m³ sypaného dříví je 1200 Kč. Cena dopravy je
            10 Kč/km.</b>
        </p>
    </div>
);
