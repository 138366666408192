import React from "react";

import {priceListUrl} from"../../constants"
import "./PriceList.scss";

export const ImageLink = ({src, alt}) => (
    <a href={src}>
        <img {...{src, alt}} />
    </a>
);

const createUrl = (imageUrl) => `${priceListUrl}/${imageUrl}`;

export const PriceList = () => (
    <div className="price-list">
        <h2>Ceník</h2>
        <p>
            Pro hrubý odhad ceny je možné zaslat fotografie stromů, přidat můžete i průměr/obvod kmene. Přesnou částku Vám sdělíme před provedením práce. Konzultace je nezávazná a zdarma. 
        </p>
        <p>
            Štěpkování – cena 700 Kč/hod bez obsluhy.
        </p>
        <p>
            Práce ve výšce od 600 Kč/hod.
        </p>
        <h3>Příklady</h3>
        <h4>Postupné kácení ořechu a srovnání na hromady – cena 4 000 Kč</h4>
        <p>
            <ImageLink src={createUrl("2.jpg")} alt="Obrázek před postupným kácením ořechu" />
            <ImageLink src={createUrl("1.jpg")} alt="Obrázek po postupném kácení ořechu" />
        </p>
        <h4>Rizikové káceni douglasky a srovnání na hromady – cena 3 500 Kč</h4>
        <p>
            <ImageLink src={createUrl("3.jpg")} alt="Obrázek rizikového káceni douglasky" />
        </p>
        <h4>Postupné kácení smrků (bez úklidu) – cena 2 000 Kč/ks</h4>
        <p>
            <ImageLink src={createUrl("4.jpg")} alt="Obrázek postupného kácení smrků" />
        </p>
        <h4>Prosvětlovací a udržovací řez jabloně – cena 200 Kč/ks</h4>
        <p>
            <ImageLink src={createUrl("5.jpg")} alt="Obrázek prosvětlovacího a udržovacího řezu jabloně" />
        </p>
        <h4>Bezpečnostní řez proschlé lípy – cena 4 000 Kč</h4>
        <p>
            <ImageLink src={createUrl("6.jpg")} alt="Obrázek bezpečnostního řezu proschlé lípy" />
        </p>
        <h4>Štěpkování</h4>
        <p>
            <ImageLink src={createUrl("7.jpg")} alt="Obrázek před štěpkováním" />
            <ImageLink src={createUrl("8.jpg")} alt="Obrázek po štěpkování" />
        </p>
        <h4>Práce ve výšce</h4>
        <p>
            <ImageLink src={createUrl("9.jpg")} alt="Obrázek práce ve výšce" />
        </p>
    </div>
);
