import React from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";

import {Contact, Arborist, HighRise, PriceList, Reference, Home, Gallery, WorkingPlatform, Firewood} from "./screens";
import {Menu, Logo, Footer} from "./components";

const Container = ({children}) => (
    <div className="page">
        <div className="header">
            <Logo />
            <Menu />
        </div>
        <div className="content">
            {children}
        </div>
        <Footer />
    </div>
);

const router = createBrowserRouter([
    {path: "/", element: <Container><Home /></Container>},
    /* New links */
    {path: "/arboristika", element: <Container><Arborist /></Container>},
    {path: "/vyskove-prace", element: <Container><HighRise /></Container>},
    {path: "/galerie", element: <Container><Gallery /></Container>},
    {path: "/cenik", element: <Container><PriceList /></Container>},
    {path: "/reference", element: <Container><Reference /></Container>},
    {path: "/kontakt", element: <Container><Contact /></Container>},
    {path: "/pracovni-plosina", element: <Container><WorkingPlatform /></Container>},
    {path: "/palivove-drevo", element: <Container><Firewood /></Container>},
    /* Fallbacks before 09/2020 */
    {path: "/Výškové-práce", element: <Container><HighRise /></Container>},
    {path: "/Ceník", element: <Container><PriceList /></Container>},
    {path: "*", element: <Container><Home /></Container>},
]);

export const Router = () => (
    <RouterProvider router={router} />
);
